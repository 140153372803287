<template>
  <div>
    <div @click="show = true">{{ code }}</div>
    <Popup v-model="show" position="bottom">
      <Picker
        :title="popTitle"
        show-toolbar
        :columns="JsonData"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        :value-key="`${[lanNow]} + `"
        :confirm-button-text="$t('Button.confirm')"
        :cancel-button-text="$t('Button.cancle')"
      >
        <template v-slot:option="row">
          {{ `${row[lanNow] || row.en} +${row.areaCode}` }}
        </template>
      </Picker>
    </Popup>
  </div>
</template>

<script>
import JsonData from "./data.json";
import { Popup, Picker } from "vant";
import { getLanguage } from "../../i18n";
export default {
  components: { Popup, Picker },
  props: {
    value: {
      type: String | Number,
      default: "",
    },
    popTitle: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
  data() {
    this.JsonData = JsonData;
    this.lanNow = getLanguage();
    return {
      show: false,
    };
  },
  created() {
    this.$emit("update", this.verConfig.areaCode);
  },
  computed: {
    code() {
      return this.value;
    },
  },
  methods: {
    onConfirm(value, index) {
      this.onCancel();
      if (value.areaCode === this.value) return;
      this.$emit("update", value.areaCode);
      this.$emit("onConfirm", value, index);
    },
    onChange() {
      this.$emit("onChange");
    },
    onCancel() {
      this.show = false;
    },
  },
};
</script>

<style>
</style>