<template>
  <div class="set_lan_wrap">
    <div
      class="lan_item"
      v-for="(item, index) in verConfig.lans || lans"
      :key="index"
      :class="{ lan_selected: isSelected(item) }"
      @click="setLans(item)"
    >
      <img :src="item.icon" class="lan_icon" />
      <div class="lan_title">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import { getLanguage, lans, setLanguage } from "@/i18n";
import verConfig from "../utils/verConfig";
export default {
  props: {
    areaCode: {
      type: Number | String,
      default: verConfig.areaCode,
    },
  },
  model: {
    prop: "areaCode",
    event: "updateAreaCode"
  },
  data: () => ({
    lans,
    lanValue: "",
  }),
  created() {
    const lansArr = this.verConfig.lans || lans;
    this.lanValue = getLanguage();
    // const currentLan = lansArr.filter(item => item.value === this.lanValue)[0] || {};
    // this.$emit("updateAreaCode", currentLan.areaCode);
  },
  methods: {
    setLans(item) {
      if (item.value === this.lanValue) return;
      // this.$emit("updateAreaCode", item.areaCode);
      this.lanValue = item.value;
      setLanguage(item.value);
      location.reload();
    },
    isSelected(item) {
      if (this.lanValue === item.value) return true;
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.set_lan_wrap {
  // position: absolute;
  // top: 6px;
  // right: 6px;
  // width: calc(100rem / 16);
  width: fit-content;
  border-radius: 6px;
  overflow: hidden;
  .lan_item {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    padding: calc(6rem / 16);
    > img {
      width: calc(40rem / 16);
      height: calc(26rem / 16);
      flex-shrink: 0;
    }
    .lan_title {
      width: 100%;
      margin-left: calc(6rem / 16);
      font-size: calc(12rem / 16);
      max-width: 60px;
    }
  }
  .lan_selected {
    background-color: #fff;
  }
}
</style>